<template>
  <el-dialog :visible="leaveFormVisible"
             @open="open"
             :before-close="close">
    <div slot="title"
         class="left_top">
      <div class="left_icon">
        <span></span>
        <span v-if="title==='在职'">办理离职</span>
        <span v-else>离职审核</span>
      </div>
    </div>
    <div class="dialog_top">
      <span>身份证图:</span>
      <div class="flex_between">
        <div class="card-box">
          <img v-if="detailInfo.idCardFront"
               :src="detailInfo.idCardFront"
               alt />
          <img v-else
               src="https://file.lgbfss.com/www/zan_xu/card_font_bg.png"
               alt />
          <p style="margin: 0; font-size: 14px">身份证头像面</p>
        </div>
        <div class="card-box">
          <img v-if="detailInfo.idCardBack"
               :src="detailInfo.idCardBack"
               alt />
          <img v-else
               src="https://file.lgbfss.com/www/zan_xu/card_back_bg.png"
               alt />
          <p style="margin: 0; font-size: 14px">身份证国徽面</p>
        </div>
      </div>
    </div>
    <el-descriptions title="用户信息">
      <el-descriptions-item label="员工姓名">{{ detailInfo.name }}</el-descriptions-item>
      <el-descriptions-item label="性别">{{ detailInfo.sex }}</el-descriptions-item>
      <el-descriptions-item label="年龄">
        <span v-if="detailInfo.idCardNumber">{{ year - parseInt(detailInfo.idCardNumber.slice(6, 10)) }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="手机号">{{ detailInfo.phone }}</el-descriptions-item>
      <el-descriptions-item label="身份证号">{{ detailInfo.idCardNumber }}</el-descriptions-item>
      <el-descriptions-item label="地址">{{ detailInfo.address }}</el-descriptions-item>
      <el-descriptions-item label="注册时间">{{ detailInfo.create_time }}</el-descriptions-item>
      <el-descriptions-item label="紧急联系人">{{ detailInfo.sosUserName }}</el-descriptions-item>
      <el-descriptions-item label="联系方式">{{ detailInfo.sosUserPhone }}</el-descriptions-item>
      <el-descriptions-item label="关系">{{ detailInfo.sosRelation }}</el-descriptions-item>
      <el-descriptions-item label="联系地址">{{detailInfo.address }}</el-descriptions-item>
      <el-descriptions-item label="用工企业">
        <span v-if="detail.workEnterprise">{{detail.workEnterprise.name}}</span>
      </el-descriptions-item>
      <el-descriptions-item label="企业区域">
        <span v-if="detail.area">{{detail.area.provinceName}}-{{detail.area.cityName}}</span>
      </el-descriptions-item>
      <el-descriptions-item label="企业门店">
        <span v-if="detail.store">{{detail.store.name}}</span>
      </el-descriptions-item>

      <el-descriptions-item label="入职时间">
        <span v-if="detail.enterTime">{{detail.enterTime }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="入职合同">
        <div v-if="enter.length">
          <span v-if=" detail.enterContractStatus==='0'">未创建</span>
          <span v-if=" detail.enterContractStatus==='2'">签署完成</span>
          <span v-if=" detail.enterContractStatus==='100'">待发起</span>
          <span v-if=" detail.enterContractStatus==='101'">未签署</span>
          <span v-if=" detail.enterContractStatus==='103'">已完成</span>
          <span v-if=" detail.enterContractStatus==='104'">审核不通过</span>
          <span v-if=" detail.enterContractStatus==='105'">待企业签署</span>
          <span v-if=" detail.enterContractStatus==='3'">签署失败</span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="工作性质">
        <span v-if="detail.kind==='1'">全职</span>
        <span v-if="detail.kind==='2'">灵活用工</span>
      </el-descriptions-item>
      <el-descriptions-item label="离职时间">
        <span v-if="detail.outTime">{{detail.outTime | dateVal}}</span>
      </el-descriptions-item>
      <el-descriptions-item label="离职合同">
        <span v-if=" detail.outContractStatus==='0'">未创建</span>
        <span v-if=" detail.outContractStatus==='2'">签署完成</span>
        <span v-if=" detail.outContractStatus==='100'">待发起</span>
        <span v-if=" detail.outContractStatus==='101'">未签署</span>
        <span v-if=" detail.outContractStatus==='103'">已完成</span>
        <span v-if=" detail.outContractStatus==='104'">审核不通过</span>
        <span v-if=" detail.outContractStatus==='105'">待企业签署</span>
        <span v-if=" detail.outContractStatus==='3'">签署失败</span>
      </el-descriptions-item>
    </el-descriptions>
    <el-form ref="ruleForm"
             :model="ruleForm"
             label-width="100px"
             :rules="rules">
      <el-row type="flex"
              class="row-bg"
              justify="space-between"
              v-if="title==='在职'">
        <el-col :span="11">
          <el-form-item label="离职时间"
                        prop="outTime">
            <el-date-picker v-model="ruleForm.outTime"
                            type="datetime"
                            placeholder="选择离职时间"></el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="11">
          <el-form-item label="合同模板">
            <el-select remote
                       reserve-keyword
                       :remote-method="query"
                       v-model="contractTemplate"
                       @change="templateChange"
                       @clear="clearTemlate"
                       clearable
                       filterable
                       placeholder="请输入合同模板"
                       v-selectLoadMore="selectLoadMore"
                       style="margin-right:10px;">
              <el-option v-for="(item,index) in contractTemplateList"
                         :key="index"
                         :label="item.name"
                         :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-card v-if="templateShow"
               style="margin:10px 0">
        <div style="flex-grow: 1">
          <Divider :title="'模板参数配置'"></Divider>
          <!-- :prop="'domains.' + i + '.val'"
            :rules="{
             required: true, message: `${item.label}不能为空`, trigger: 'blur'
          }"-->
          <el-form-item v-for="(item, i) in templeteObject.fields"
                        :key="i">
            <div slot="label">
              <div class="label_class">
                <span style="width: 100px;">
                  {{ item.label === '甲方名称'
                  ? '甲方签署人'
                  : item.label === '乙方名称'
                  ? '乙方签署人'
                  : item.label}}
                </span>
              </div>
            </div>
            <el-input v-model="item.value"
                      :placeholder="item.label"
                      v-if="item.type === 'text'"
                      @input="inputChange"></el-input>

            <div v-if="item.type === 'subject'">
              <SelectSubject :value.sync="item.value"></SelectSubject>
            </div>

            <el-input type="number"
                      v-model="item.value"
                      :placeholder="item.label"
                      v-if="item.type === 'number'"
                      @input="inputChange"></el-input>

            <el-input v-model="item.value"
                      :placeholder="item.label"
                      v-if="item.type === 'defined'"
                      @input="inputChange"></el-input>

            <el-date-picker style="width: 100%"
                            v-if="item.type === 'time'"
                            v-model="item.value"
                            type="date"
                            :placeholder="item.label"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @input="inputChange"></el-date-picker>
          </el-form-item>
        </div>
      </el-card>

      <el-form-item label="审核结果"
                    prop="isVerifyPass"
                    v-if="title!='在职'">
        <el-radio-group v-model="ruleForm.isVerifyPass">
          <el-radio :label="true"
                    :disabled="templateShow">通过</el-radio>
          <el-radio :label="false"
                    :disabled="templateShow">拒绝</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="备注"
                    v-if="title==='在职'">
        <el-input type="textarea"
                  :disabled="templateShow"
                  v-model="ruleForm.outerApplyRemark"></el-input>
      </el-form-item>
      <el-form-item label="备注"
                    v-else>
        <el-input type="textarea"
                  :disabled="templateShow"
                  v-model="ruleForm.noPassEnterReason"></el-input>
      </el-form-item>

      <el-form-item>
        <span v-if="title==='在职'"
              style="margin-right:10px">
          <el-button type="primary"
                     v-if="templateShow"
                     @click="onSubmitContract('ruleForm')">创建合同</el-button>
          <el-button type="primary"
                     v-else
                     @click="onSubmit('ruleForm')">提交</el-button>
        </span>

        <el-button v-else
                   type="primary"
                   @click="goSubmit('ruleForm')">审批</el-button>
        <el-button @click="closeDialog('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { api } from '/src/api/base';
import { ContractTemplate } from '../contract/contractParse';
const staffInfo = api()('staff.info.json');
const staffWorkerOuter = api()('staff.worker.outer.json');

// 企业列表
export const staffWorkerOuterApply = api()('staff.worker.outer.apply.json');
export const workEnterpriseList = api()('work_enterprise.list.json');
export const contractTemplateDescribe = api()('contractTemplate.enterprise.info.json');
export const workEnterpriseAreaList = api()('work_enterprise_area.list.json');
export const workEnterpriseStoreList = api()('work_enterprise_store.list.json');
export const workEnterpriseAreaContainArea = api()('work_enterprise_area.containArea.json');
export const contractTemplateEnterpriseList = api()(
  'contractTemplate.enterprise.list.json'
);
export const staffEdit = api()('staff.edit.json');
export const contractAdd = api()('contract.add.json');

export default {
  components: {
    Divider: () => import('/src/components/divider.vue'),
    SelectSubject: () => import('/src/views/contract/selectSubject')
  },
  data () {
    return {
      detailInfo: {},
      detail: {},
      year: new Date().getFullYear(),
      eList: [],
      storeList: [],
      areaSelectList: [],
      choice: false,
      templeteObject: null,
      ruleForm: {

        outTime: '',
        outerApplyRemark: '',
        isVerifyPass: '',
        noPassEnterReason: '',

      },
      contractTemplate: '',
      rules: {
        outTime: [{

          required: true,
          message: '请选择日期',
          trigger: 'change',
        },
        ],
        isVerifyPass: [
          {
            required: true,
            message: '请选择审核结果',
            trigger: 'change',
          },
        ],
      },
      templateShow: false,
      contractTemplateList: [

      ],
      name: '',
      type: '',
      enter: [],
      out: [],
      totalPage: '',
      pageNumber: 1,
      pageSize: 10,
    };
  },
  props: {
    leaveFormVisible: Boolean,
    title: String,
    code: String
  },

  methods: {
    async selectLoadMore () {

      this.pageNumber = this.pageNumber + 1;
      if (this.pageNumber > this.totalPage) return;
      await this.getTemplate(); // 请求接口
    },
    getTemplate () {
      contractTemplateEnterpriseList({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        workEnterpriseCode: this.ruleForm.workEnterpriseCode,
        workEnterpriseArea: this.ruleForm.workEnterpriseAreaCode,
      }).then(res => {
        this.totalPage = Math.ceil(res.total / 10);
        this.total = res.total;
        this.contractTemplateList = this.contractTemplateList.concat(res.list)
        console.log(this.contractTemplateList);
      });
    },
    inputChange () {

    },
    onSubmitContract (formName) {

      var domains = this.templeteObject.fields.every(it => {
        if (it.type === 'subject') {
          if (it.value.psnId != '') {
            return true
          }
          else {
            return false
          }
        }
        else {
          if (it.value != '') {
            return true
          }
          else {
            return false
          }
        }
      })
      if (!domains) {
        this.$message({
          message: '请输入合同模板信息',
          type: 'danger'
        })

        return
      }
      else {
        const a = this.templeteObject.getReplaceConfigTwo()
        const isShow = new Set(a.map(m => m.psnId)).size === this.templeteObject.fields.filter(it => it.type === 'subject').length
        if (isShow) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.TimeChange(this.ruleForm.outTime)
              staffEdit({
                staffCode: this.code,
                outTime: this.ruleForm.outTime,

              }).then(res => {
                console.log(res);
                this.createContract()
                this.onSubmit(formName, '创建合同')


              });
            }
            else {
              return false
            }
          })
        }
        else {
          this.$message.info('甲方签署人和乙方签署人不能为同一人');
        }
      }

    },
    createContract () {

      contractAdd({
        name: this.name,
        workEnterpriseCode: this.detail.workEnterpriseCode,
        workEnterpriseAreaCode: this.detail.workEnterpriseAreaCode,
        workEnterpriseStoreCode: this.detail.workEnterpriseStoreCode,
        templateCode: this.contractTemplate,
        content: JSON.stringify(this.templeteObject.getReplaceContract()),
        config: '[' + JSON.stringify(this.templeteObject.getReplaceConfigTwo()) + ']',
        type: this.type

      }).then(() => {

        this.$message({
          message: '合同创建成功',
          type: 'success'
        })
      });

    },
    TimeChange (e) {
      var that = this
      var date = new Date(e);
      var y = date.getFullYear(); // 年
      var m = date.getMonth() + 1; // 月
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate(); // 日
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours(); // 时
      h = h < 10 ? ('0' + h) : h;
      var min = date.getMinutes(); // 分
      min = min < 10 ? ('0' + min) : min;
      var s = date.getSeconds(); // 秒
      s = s < 10 ? ('0' + s) : s;
      that.ruleForm.outTime = y + '-' + m + '-' + d + '-' + h + ':' + min + ':' + s;//拼在一起


    },

    async templateChange (val) {
      this.templateShow = true
      const result = await contractTemplateDescribe({ code: val });
      this.templeteObject = new ContractTemplate(result);

      this.name = result.name
      this.type = result.type


    },


    query (val) {

      this.pageNumber = 1;
      this.enterpriseList(val);
    },
    onSubmit (formName, title) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!title) {
            this.TimeChange(this.ruleForm.outTime)
          }

          const params = {
            staffCode: this.code,

            outTime: this.ruleForm.outTime,

            outerApplyRemark: this.ruleForm.outerApplyRemark
          };
          staffWorkerOuterApply(params).then(() => {


            this.closeDialog(formName)
          });
        } else {
          return false
        }
      })
    },
    goSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          const params = {
            staffCode: this.code,

            isVerifyPass: this.ruleForm.isVerifyPass,
            noPassEnterReason: this.ruleForm.noPassEnterReason

          };
          staffWorkerOuter(params).then(() => {
            this.closeDialog(formName)
          });
        } else {
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    open () {
      this.geiInfo()


    },
    clearTemlate () {
      this.templateShow = false
    },

    async geiInfo () {

      await staffInfo({ staffCode: this.code }).then((res) => {
        if (res) {
          this.detailInfo = res.user;
          this.detail = res
          this.enter = res.contract.filter(it => it.type === '1')
          this.out = res.contract.filter(it => it.type === '2')

          this.getTemplate()

        }
      });
    },
    close () {
      this.closeDialog('ruleForm')
    },
    closeDialog (val) {
      this.contractTemplate = ''
      this.contractTemplateList = []
      this.templateShow = false
      this.resetForm(val)
      this.$emit('update:leaveFormVisible', false);
      this.$emit('func', this.leaveFormVisible);
    }
  }
};
</script>
<style>
.el-form-item__label {
  display: flex !important;
  padding-bottom: 0 !important;
}
</style>
<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .card-box {
    margin: 10px;
    padding: 1px;
    text-align: center;
    color: grey;
    width: 50%;

    img {
      width: 100%;
      height: 100px;
    }
  }
}

.el-form-item__label {
  padding-bottom: 0 !important;
}

.dialog_top {
  display: flex;
  align-items: center;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
